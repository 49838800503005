<template>
  <v-container class="text-center">
    <div
      v-if="isPreview"
      class="text-center"
    >
      <div class="d-flex justify-center ma-4">
        <v-row class="d-flex justify-center">
          <v-col
            lg="4"
            md="6"
            sm="6"
          >
            <v-img
              :src="thumburl"
              aspect-ratio="1"
              max-height="400"
              max-width="400"
            />
            <!-- <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tryme-eeb82.appspot.com/o/angry.jpg?alt=media&token=1e4160e9-5617-4468-a93a-75eddea638f9"
            /> -->
          </v-col>
        </v-row>
      </div>
      <div class="ma-4 text-h4">
        {{ title }}
      </div>
      <div class="text-h6">
        <div
          class="ma-5"
          v-html="content"
        />
      </div>
      <v-spacer />
      <v-spacer />
      <v-spacer />
      <div class="ma-5">이 테스트는 {{ count }}번 진행되었어요!</div>
    </div>
    <v-row
      class="ma-2"
      align="center"
      justify="center"
    >
      <v-col>
        <v-btn
          v-if="isPreview"
          color="primary"
          @click="showTest"
        >
          테스트 시작하기
        </v-btn>
      </v-col>
    </v-row>

    <qna
      v-if="!isPreview"
      :theme-id="themeId"
      :qqq="qlist"
      :total-count="totalCount"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    Qna: () => import('@/components/test/qna/Qna'),
  },
  data() {
    return {
      isPreview: false,
      themeId: this.$route.params.themeId,
      count: 0,
      page: 0,
      content: '',
      test: '',
      title: '',
      randomtest: false,
      totalCount: 0,
      isRandom: false,
      thumburl: '',
      qlist: [],
    }
  },
  async mounted() {
    if (this.themeId == 'random') {
      this.isRandom = true
      this.randomtest = true
      const max = this.$store.state.qna.ranking.length
      const random = Math.floor(Math.random() * (max - 0))
      this.themeId = this.$store.state.qna.ranking[random].id
      this.$router.push('/test/' + this.themeId)
    }

    const themelist = this.$store.state.qna.ranking // this.$store.getter('qna/fetchRanking')
    await this.$store.dispatch('qna/fetchQs', { themeId: this.themeId })

    const dist = this.$store.state.qna.qs.info
    this.qlist = this.$store.state.qna.qs.qlist

    this.title = dist.title
    this.count = dist.count
    this.content = dist.content
    this.thumburl = dist.thumburl

    this.totalCount = this.qlist.length

    this.isPreview = true
  },
  methods: {
    showTest() {
      this.isPreview = !this.isPreview
    },
  },
}
</script>

<style></style>
